body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//
.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.filter-container {
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 1;
  //background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 230px; // Adjust the width as needed
}

.filter-container select {
  padding: 5px;
  font-size: 14px;
}

/* Cible la boîte de recherche */
.mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: 300px;
  //background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 5px;

  &--button {
    border: none;
    background: transparent;

    &:hover {
      background-color: transparent !important;
    }
  }

  &--input,
  &--input:focus {
    border-radius: 5px;
    border: none;
    padding: 8px 10px;
    font-size: 14px;
    outline: none;
    flex-grow: 1;
  }

  &--icon-search {
    color: #333;
    padding: 5px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--icon-close {
    color: #ff0000;
    padding: 0;
    margin-right: 5px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--powered-by,
  &--icon-loading,
  &--pin-right {
    display: none !important;
  }
}

/* Styliser la liste des résultats */
.suggestions {
  //background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  left: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;

  a {
    color: #333;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      //background-color: #f0f0f0;
    }

    .suggestion-icon {
      margin-right: 10px;
      font-size: 18px;
      color: #666;
    }

    .suggestion-title {
      font-weight: bold;
      margin-bottom: 5px;
      display: block;
    }

    .suggestion-subtitle {
      font-size: 12px;
      color: #999;
    }
  }
}

.spider-leg-line {
  stroke: black !important;
  stroke-width: 2px !important;
}

input,
textarea {
  font-size: 16px !important;
}

.mapboxgl-ctrl-top-right {
  margin-top: 64px;
}