#popup-container {
    //background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 10px;
    font-family: 'Arial', sans-serif;

    h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.5em;
        //color: #333333;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
    }

    p {
        //margin: 10px 0;
        padding: 0;
        font-size: 1em;
        //color: #555555;
    }

    .icon {
        color: #1a73e8;
        //text-transform: uppercase;

        &:hover {
            color: #0c59d8;
        }
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .icon-button {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            //background-color: #f5f5f5;
            border: 1px solid #ddd;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #ddd;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}

.draggable-popup {
    width: 400;
    /* Adjust width as needed */
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
}

.popup-header {
    cursor: move;
    background: #f1f1f1;
    padding: 5px;
    border-radius: 5px 5px 0 0;
}

.popup-container {
    //background: white;
    //border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.draggable-handle {
    //background: #f1f1f1;
    padding: 5px;
    cursor: move;
    border-radius: 5px 5px 0 0;

    width: 100%;
    margin-right: -2rem;
}