.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .icon-button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #ddd;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        span {
            text-transform: uppercase;
        }
    }
}